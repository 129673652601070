









































import Vue from 'vue'
import { Duration, DurationObjectUnits } from 'luxon'
import { Component, Prop } from 'vue-property-decorator'

import { Button, sanitizeTemplate } from '@focus/components'

import { Group } from '../groups.types'
import { displayDurationFromMinutes } from 'staff/src/domains/core/date.utilities'

@Component({
  components: {
    Button
  }
})
export default class GroupCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly group!: Group

  get description (): string {
    return this.group.job.description ? sanitizeTemplate(this.group.job.description) : ''
  }

  get incentiveAmount () {
    return this.group.incentiveAmount.toFixed(2)
  }

  get duration () {
    return displayDurationFromMinutes(this.group.durationMinutes)
  }

  date (date: Date): string {
    date = new Date(date)
    const time = date.toLocaleTimeString('en-AU', { hour: 'numeric', minute: '2-digit' })
    const day = date.toLocaleDateString('en-AU', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    return `${time}, ${day}`
  }
}
