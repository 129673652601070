















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Button, capitalize } from '@focus/components'

import { Group } from '../groups.types'

import GroupDetailSection from './GroupDetailSection.vue'

@Component({
  components: {
    Button,
    GroupDetailSection
  }
})
export default class GroupCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly group!: Group

  private get contactEmail () {
    // TODO - Move this to a const file
    return 'mailto:inquiries@chitchatresearch.com.au'
  }

  get name (): string {
    return capitalize(this.group.job.name)
  }
}
