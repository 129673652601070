
























import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'

import GroupCard from '../components/GroupCard.vue'
import NoGroupCard from '../components/NoGroupCard.vue'
import { Group } from '../groups.types'
import { ApiError } from '@focus/components'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: {
    GroupCard,
    NoGroupCard,
    Header
  }
})
export default class GroupListPage extends BaseComponent {
  groups: Group[] = []

  async mounted () {
    try {
      this.groups = await this.$store.dispatch('groups/upcoming', { id: this.$store.state.auth.user.id })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
