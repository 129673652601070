import { Duration, DurationLikeObject, DurationObjectUnits } from 'luxon'

function displayDuration (data: DurationLikeObject, units: (keyof DurationObjectUnits)[]) {
  const duration = Duration.fromObject(data).shiftTo(...units)
  return duration.toHuman()
}

export function displayDurationFromMinutes (minutes: number) {
  const units: (keyof DurationObjectUnits)[] = []
  if (minutes >= 60) {
    units.push('hours')
  }

  if (minutes % 60 !== 0) {
    units.push('minutes')
  }

  return displayDuration({ minutes }, units)
}
